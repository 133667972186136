<template>
  <div class="DAV-workshop__trunk">
    <component-title color="green" @goPrev="goPrev" />
    <div
      class="DAV-blockClass"
      :class="{
        'DAV-blockClass--option-mode': this.$route.path === '/option'
      }"
    >
      <div class="multiple-input-wrap">
        <textarea
          id="textarea"
          class="frmBlock__textField multiple-textarea"
          type="text"
          placeholder="내용을 입력해주세요."
          @input="changeInput"
          v-model="data.text"
        />
        <div class="frmBlock__title frmBlock__title--last">
          URL
          <tooltip
            top="30px"
            :text="`도메인이 포함된 URL을 입력하는 경우 https:// 또는 http:// 를 함께 입력해주세요.<br/>
            ･ 예) https://qpicki.com`"
          />
        </div>
        <div class="frmBlock__layout frmBlock__layout--grow">
          <TextField
            placeholder="URL을 입력해주세요."
            :value="data.url"
            @input="v => updateValue('url', v)"
          />
          <Dropdown
            :value="data.target"
            @updateTarget="v => updateValue('target', v)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
const ModuleEditor = createNamespacedHelpers("ModuleEditor");

export default {
  data() {
    return {
      data: {
        text: "",
        url: "",
        target: "_self",
        enabled: true
      }
    };
  },
  computed: {
    ...ModuleEditor.mapGetters(["getPopup"])
  },
  mounted() {
    this.data = this.getPopup.value;
    this.$nextTick(() => {
      document.getElementById("textarea").style.height = "96px";
      document.getElementById("textarea").style.height = `${
        document.getElementById("textarea").scrollHeight
      }px`;
    });
  },
  methods: {
    // 이전 리스트로 이동
    goPrev() {
      this.$store.commit("ModuleEditor/setPopup", {
        page: null
      });
    },
    // textarea change Input
    changeInput(e) {
      this.updateValue("text", e.target.value);
      e.target.style.height = "96px";
      e.target.style.height = `${e.target.scrollHeight}px`;
    },
    // update value
    updateValue(type, value) {
      let id =
        this.$route.path === "/option"
          ? this.getPopup.option.componentOptionId
          : this.getPopup.option.id;

      if (type === "url") {
        this.data.url = value;
      }
      if (type === "target") {
        this.data.target = value;
      }
      if (type === "text") {
        this.data.text = value;
      }
      this.$EventBus.$emit(
        `MultiTextURLUpdateValue${id}`,
        this.data,
        this.getPopup.index
      );
    }
  },
  components: {
    ComponentTitle: () => import("@/components/editor/ComponentTitle.vue")
  }
};
</script>

<style lang="scss" scoped>
.multiple-input-wrap {
  background: #fff;
  padding: 24px 16px;

  .multiple-textarea {
    resize: none;
    padding: 12px 16px;
    overflow: hidden;
  }
}

/* 반응형 - desktop */
@media screen and (min-width: 1024px) {
  .DAV-workshop__heading {
    h3 {
      padding-left: 32px;
    }
  }
}
/* 반응형 - mobile */
@media screen and (max-width: 1023px) {
  .DAV-blockClass {
    &--option-mode {
      top: 57px;
    }
  }
  .DAV-workshop__heading {
    display: none !important;
    &--option-mode {
      display: block !important;
    }
  }
}
</style>
